@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  margin-bottom: 30px;
}

.server-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 5px;
}

.status-indicator {
  font-size: 20px;
}

.status-indicator.online {
  color: green;
}

.status-indicator.offline {
  color: red;
}

.status-indicator.checking {
  color: gray;
}